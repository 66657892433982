<template>
  <div class="i-tab" :class="{border:border}">
    <div class="i-tab-item " v-for="(item, index) in tabs" :style="leftStyle"
      :class="{active: index == currentTab && !color, 'primary--text': index == currentTab, 'secondary--text': index != currentTab}"
      :key="index"
      @click="currentTab=index">{{item}}</div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => ([])
    },
    value: {
      type: Number,
      default: 0
    },
    border: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    color: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    leftStyle() {
      if (this.left) {
        return {
          'padding-left': '10px',
          'text-align': 'left'
        }
      } else {
        return {}
      }
    }
  },
  watch: {
    value() {
      this.currentTab = this.value
    },
    currentTab(val) {
      this.$emit('input', val)
    }
  },
  data() {
    return {
      currentTab: this.value
    }
  }
}
</script>

<style lang="scss" scoped>
.i-tab {
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #DCDFE6;
  &.border {
    border: 1px solid #EBEEF5;
  }
  .i-tab-item {
    width: 0;
    flex: 1 1 auto;
    text-align: center;
    font-size: 20px;
    height: 44px;
    //margin: 4px;
    cursor: pointer;
    line-height: 44px;
    // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2) inset, 0px 1px 1px 0px rgba(0, 0, 0, 0.14) inset, 0px 1px 3px 0px rgba(0, 0, 0, 0.12) inset;
    &.active {
      background-color: #FFF;
      cursor: default;
      box-shadow: none;
    }
  }
}
</style>
